/* global _paq */
import React, { useEffect, useState, useContext } from "react"
import "../wdyr"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"
import Heading from "atomic-core/components/patterns/01-atoms/texts/heading/Heading"
import PageAside from "../components/streaming/pageAside"
import Slides from "../components/streaming/slides"
import SEO from "../components/seo"
import {
  PRESENTATION_STATUS_LIVE,
  PLAYER_EVENT_PAUSE,
} from "../utils/constants"
import { playerEvent } from "../utils/analytics"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVideo, faImages } from "@fortawesome/free-solid-svg-icons"
import miniplayer from "../images/miniplayer.svg"
import ReactTooltip from 'react-tooltip'
import { miniPlayerContext } from "../context/miniPlayer";
import { livePlayerContext } from "../context/livePlayer";
import { SocketContext } from "../context/socket";
import { isMatomoEnabled } from '../data/global';
import { handleRemoveLiveViewer } from '../utils/socket/socketOperations';

const LivePage = () => {
  const [miniPlayerState, setminiPlayerState] = useContext(miniPlayerContext)
  const [livePlayerState] = useContext(livePlayerContext)
  const { socket, isConnected } = useContext(SocketContext);
  const [slideshowFullscreen, setSlideshowFullscreen] = useState(false)
  const [toggle, setToggle] = useState(false)
  const livePlayerData = livePlayerState?.data;
  const loading = livePlayerState?.isLoading;
  useEffect(() => {
    function handleOnlineStatusChange() {
      if (navigator.onLine) {
        if (
          (livePlayerState && livePlayerState?.eventType === PLAYER_EVENT_PAUSE) ||
          (miniPlayerState && miniPlayerState.eventType === PLAYER_EVENT_PAUSE)
        ) {
          playerEvent({
            eventType: PLAYER_EVENT_PAUSE,
            presentationId: livePlayerData?._id,
            status: PRESENTATION_STATUS_LIVE,
          });
          if(isConnected){
            handleRemoveLiveViewer(socket,livePlayerData?._id);
          }
        }
      }
    }
    window.addEventListener('online', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
    };
  }, [livePlayerState, miniPlayerState]);

  useEffect(() => {
    if (((miniPlayerState?.isAutoMiniPlayer) || (!miniPlayerState?.isAutoMiniPlayer && !miniPlayerState?.showVideoAtBottom))) {
      const appendPlayer = (player) => {
        const livePagePlayerEle = document.getElementById("live-page-player");
        if (livePagePlayerEle) {
          livePagePlayerEle.appendChild(player);
          setminiPlayerState((prev)=>({...prev,showVideoAtBottom: false,isAutoMiniPlayer:false}))
        }
      };
      let player = document.getElementById("live-stream-player");
      if (player) {
        appendPlayer(player);
      }
      const playerObserver = new MutationObserver(() => {
        player = document.getElementById("live-stream-player");
        if (player) {
          appendPlayer(player);
          playerObserver.disconnect();
        }
      });
      playerObserver.observe(document.body, { childList: true, subtree: true });
    }
    return () => {
      let playerEleVideo = document.getElementsByTagName("video")[0];
      let miniPlayerEle = document.getElementById("mini-player-wrapper");
      if (playerEleVideo) {
        if (playerEleVideo.paused && !miniPlayerEle) {
          const player = document.getElementById("live-stream-player");
          const observer1 = new MutationObserver(() => {
            let layoutPlayerEle = document.getElementById("layout-player");
            if (layoutPlayerEle) {
              layoutPlayerEle.appendChild(player);
              observer1.disconnect();
            }
          });
          observer1.observe(document.body, { childList: true, subtree: true });
        } else if (!miniPlayerEle) {
          setminiPlayerState((prev)=>({...prev,showVideoAtBottom: true, isAutoMiniPlayer: true}))
          const player = document.getElementById("live-stream-player");
          const observer1 = new MutationObserver(() => {
            let layoutPlayerEle = document.getElementById("mini-player");
            if (layoutPlayerEle) {
              layoutPlayerEle.appendChild(player);
              observer1.disconnect();
            }
          });
          observer1.observe(document.body, { childList: true, subtree: true });
        }
      }
    }
  }, [])

  if (typeof window !== "undefined" && !isLoggedIn()) {
    navigate("/")
    return null
  }

  if (loading) {
    return <div className="page live-stream">
      <div className="page-content">
        <div>
          Loading...
        </div>
      </div>
    </div>
  }

  return (
    <>
      <SEO title="Live Stream" />
      {livePlayerData && Object.keys(livePlayerData).length ? (
        <div className="page live-stream">
          <div className={`page-content`}>
            <Heading
              headingType={"h2"}
              globalModifiers={
                "d-xs-none d-sm-block text-size-h3 font-primary-bold mb-20"
              }
            >
              {livePlayerData?.name}
            </Heading>

            <div className={"container-fluid no-gutters no-gutters"}>
              <div className={"row no-gutters xs-center"}>
                {!miniPlayerState?.showVideoAtBottom && !livePlayerData?.hideSlides && (
                  <div className="col-xs-12  mb-20 d-sm-inline-block d-xs-none">
                    <input
                      id="sizeChanger"
                      type="checkbox"
                      checked={toggle}
                      onChange={() => {
                        setToggle(!toggle)
                      }}
                    />
                    <div className="d-xs-flex xs-center">
                      <div className="mr-10">
                        <FontAwesomeIcon
                          icon={faVideo}
                          className={"svg color-monochrome-2 text-size-regular"}
                        />{" "}
                        Video
                      </div>
                      <div className="sizeChanger">
                        <label htmlFor="sizeChanger" id="sizeChangerLabel">
                          <span className="switch"></span>
                        </label>
                      </div>
                      <div className="ml-10">
                        <FontAwesomeIcon
                          icon={faImages}
                          className={"svg color-monochrome-2 text-size-regular"}
                        />{" "}
                        Presentation
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={
                    !toggle
                      ? `col-sm-12 ${livePlayerData?.hideSlides ? "col-md-12" : "col-md-4"} sm-row md-col d-xs-block mb-sm-20`
                      : "col-sm-12 col-md-8"
                  }
                >
                  <div
                    className="react-jw-player-container pr-sm-0 pr-xs-0"
                    data-name={livePlayerData?.name}
                    id="live-page-player"
                  >
                    {miniPlayerState.showVideoAtBottom &&
                      <div style={{
                        width: '100%',
                        height: `${toggle ? "400px" : "200px"}`,
                        backgroundColor: '#343131',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '5px',
                        boxShadow: "rgba(100, 100, 111, 10) 0px 7px 29px 0px"
                      }}>Switched to Mini-Player mode</div>}
                  </div>
                  <div className="miniplayer-button-container d-xs-flex xs-center text-size-medium">
                    <a
                      className="pointer-cursor xs-self-center"
                      data-tip={"Switch to MiniPlayer to navigate <br/> to other areas of the platform."}
                      data-multiline={true}
                      data-class="ve-tooltip"

                      onClick={() => {
                        if (!miniPlayerState?.showVideoAtBottom) {
                          setminiPlayerState((prev)=>({...prev,showVideoAtBottom: true, isAutoMiniPlayer: false}))
                          const player = document.getElementById("live-stream-player");
                          const observer = new MutationObserver(() => {
                            const miniPlayerEle = document.getElementById("mini-player");
                            if (miniPlayerEle) {
                              miniPlayerEle.appendChild(player);
                              observer.disconnect();
                            }
                          });
                          observer.observe(document.body, { childList: true, subtree: true });
                        } else {
                          setminiPlayerState((prev)=>({...prev,showVideoAtBottom: false, isAutoMiniPlayer: false}))
                          const player = document.getElementById("live-stream-player");
                          const observer = new MutationObserver(() => {
                            const livePagePlayerEle = document.getElementById("live-page-player");
                            if (livePagePlayerEle) {
                              livePagePlayerEle.appendChild(player);
                              observer.disconnect();
                            }
                          });
                          observer.observe(document.body, { childList: true, subtree: true });
                        }
                      }}
                    >
                      <img
                        src={miniplayer}
                        width="20"
                        className="mr-5 mt-5"
                      />
                      <ReactTooltip />
                    </a>
                  </div>
                </div>
                {!livePlayerData?.hideSlides && livePlayerData?.slides && livePlayerData?.slides.length > 0 && (
                  <div
                    className={
                      toggle
                        ? "col-sm-12 col-md-4 sm-row md-col d-sm-flex d-md-block mb-sm-20"
                        : "col-sm-12 col-md-8"
                    }
                  >
                    <Slides
                      slides={livePlayerData?.slides}
                      slideshowFullscreen={slideshowFullscreen}
                      onClick={() =>
                        setSlideshowFullscreen(!slideshowFullscreen)
                      }
                      activeSlideID={miniPlayerState?.activeSlideFromMP}
                    />
                  </div>
                )}
                {!livePlayerData?.hideSlides && livePlayerData?.slides && livePlayerData?.slides.length == 0 && (
                  <div className={"col-sm-12 col-md-8 slide-text-center"}>
                    No slides currently available
                  </div>
                )}
                <div className={"d-xs-flex col-sm-12 speaker-info xs-wrap"}>
                  <h3
                    className={
                      "d-xs-flex heading width-full mt-20 text-size-medium font-secondary-bold"
                    }
                  >
                    Speaker
                  </h3>
                  <div className={"d-xs-flex width-full xs-wrap"}>
                    {livePlayerData?.speakers &&
                      livePlayerData?.speakers.length &&
                      livePlayerData?.speakers.map((speaker, idx) => (
                        <div
                          key={idx}
                          className="d-xs-flex col-xs-12  col-sm-6 col-md-4 col-lg-3  xs-top mb-20"
                        >
                          <div className="mr-10">
                            <img
                              className="radius-circle"
                              src={speaker.speaker_image_path}
                              height="50px"
                              width="50px"
                            />
                          </div>
                          <div className="font-secondary-regular">
                            <div>{speaker.speaker_name}</div>
                            <div>{speaker.speaker_company}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(livePlayerData?.isPollTabVisible || livePlayerData?.isQuestionTabVisible || livePlayerData?.hasLiveChat) && (
            <PageAside
              presentationId={livePlayerData?._id}
              moderatedQuestions={livePlayerData?.moderatedQuestions}
              isPollTabVisible={livePlayerData?.isPollTabVisible}
              isQuestionTabVisible={livePlayerData?.isQuestionTabVisible}
              hasLiveChat={livePlayerData?.hasLiveChat}
            />
          )}
        </div>
      ) : (!livePlayerState?.presentationId ? (
        <h3 className="d-xs-flex xs-center xs-middle width-full">
          There is currently no live event. Check agenda for upcoming sessions.
        </h3>
      ) : "")}
    </>
  )
}

export default LivePage
