import React, { useState, useEffect, useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane, faSync, faCaretSquareUp } from "@fortawesome/free-solid-svg-icons"
import { Translations } from "../../../utils/translations"

const Questions = props => {
  const userId = JSON.parse(window?.localStorage?.veUser)?.userId;
  const { setSpin, spin } = props
  const [questionSubmitted, setquestionSubmitted] = useState(false)
  const [questionAsked, setQuestionAsked] = useState("")
  const [questionError, setQuestionError] = useState(false)
  const [displayCaptcha, setDisplayCaptcha] = useState(false)
  const [showCaptchaError, setShowCaptchaError] = useState(false)
  const [gReCaptchaResponse, setGReCaptchaResponse] = useState("")

  const submitQuestion = async () => {
    if (questionAsked && questionAsked.trim() !== "") {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          question: questionAsked.trim(),
          eventId: process.env.EVENT_ID,
          gReCaptchaResponse: gReCaptchaResponse,
        }),
      }

      const response = await fetch(
        `${process.env.API_URL}/${process.env.EVENT_ID}/${props.presentationId}/submitquestion`,
        requestOptions
      ).then(res => res.json())
      if (response && response.status === 200 && response.result) {
        setQuestionAsked("")
        setDisplayCaptcha(false)
        setGReCaptchaResponse("")
        setShowCaptchaError("")
        setquestionSubmitted(true)
        //If questions are not moderated, append the latest question to the questions list.
        if (!props.moderatedQuestions) {
          props.updateQuestionsList(response.result)
        }
      } else {
        setDisplayCaptcha(true)
        if (displayCaptcha) {
          setShowCaptchaError(true)
        }
      }
    } else {
      setQuestionError(true)
      // alert("Please type the Question")
    }
  }

  const question = event => {
    setQuestionError(false)
    questionSubmitted && setquestionSubmitted(false)
    setQuestionAsked(event.target.value)
  }

  const renderCaptcha = () => {
    window.onCaptchaStateChange = () => {
      const captchaResponse = window.grecaptcha.getResponse()
      setGReCaptchaResponse(captchaResponse)
    }
    window.recaptchaExpired = () => {
      window.grecaptcha.reset()
      setGReCaptchaResponse("")
    }
  }

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js"
    script.async = true
    script.defer = true
    document.body.appendChild(script)
  }, [displayCaptcha])

  useEffect(() => {
    renderCaptcha()
  }, [gReCaptchaResponse])

  return (
    <div className="questions-block d-xs-flex xs-column xs-between height-full">
      <div className="pl-15 pr-15 pt-15 pb-15 tabs-scrollable-wrapper">
        <div className="xs-between d-xs-flex xs-baseline">
          <h5
            className={
              "font-secondary-bold text-size-regular color-secondary-2 mb-10 position-relative"
            }
          >
            Moderated questions
          </h5>
          <span
            className="icon refresh-icon icon-size-25 color-monochrome-1 background-color-primary-2 text-align-center"
            onClick={() => {
              props.fetchQuestions()
              setquestionSubmitted(false)
              setSpin(true)
            }}
          >
            <FontAwesomeIcon icon={faSync} spin={spin} />
          </span>
        </div>
        {props.questionsData && (
          <ul className="font-secondary-regular color-secondary-2 pl-0">
            {props.questionsData.map((question, index) => {
              return (
                <li key={question._id} className="mt-10 mb-10">
                  <hr className="color-secondary-1"></hr>
                  <div className="question-wrapper">
                    <div style={{ flex: 1 }}>{question.value}</div>

                    {props.isUpvotesEnabled &&
                      <UpvoteElement upvotes={question?.upvotes || []} index={index} handleToggleVote={props?.updateUpvotes} userId={userId} key={question?.upvotes?.length || index} />
                    }

                  </div>
                </li>
              )
            })}
            <hr className="color-secondary-1"></hr>
          </ul>
        )}
        {questionSubmitted && (
          <div className="pl-15 pr-15 pt-15 pb-15">
            <p className="font-secondary-regular color-secondary-2">
              Thank you for submitting your question.
            </p>
            {props.moderatedQuestions && (
              <p className="font-secondary-regular color-secondary-2">
                All questions are reviewed by the moderators. If your question
                is accepted it will be added to the queue above.
              </p>
            )}
          </div>
        )}
      </div>
      <div>
        <div className="type-question font-secondary-regular">
          {displayCaptcha && (
            <div className="pl-15 pr-15 pt-15 pb-15">
              <div
                className="g-recaptcha"
                data-sitekey={process.env.GOOGLE_RECAPTCHA_SITEKEY}
                data-callback="onCaptchaStateChange"
                data-expired-callback="recaptchaExpired"
              ></div>
              {!gReCaptchaResponse && showCaptchaError && (
                <div className="form-item__error-message">
                  {Translations.ERROR.CAPTCHA_REQUIRED}
                </div>
              )}
            </div>
          )}
          <div className="form-item__control textfield form-item__control--icon-right width-full">
            <textarea
              className={`form-control width-full ${questionError ? "error" : ""
                }`}
              placeholder="Type your question"
              id="input2"
              name="question"
              autoComplete="off"
              onChange={question}
              value={questionAsked}
              onKeyDown={event => {
                if (event.key === "Enter") {
                  submitQuestion()
                }
              }}
            />
            <span
              onClick={submitQuestion}
              className="icon icon-size-25 mr-15 color-monochrome-1 background-color-primary-2 text-align-center _a_sendQuestion"
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
const UpvoteElement = ({ upvotes, index, handleToggleVote, userId }) => {
  return (
    <div className={`question-vote-wrapper transparent-vote-bg ${upvotes?.includes(userId) ? "border-color-primary-2" : ""}`}>
      <div>{upvotes?.length || 0}</div>
      <div className="pointer-cursor" onClick={() => {
        handleToggleVote(index);
      }}>
        <FontAwesomeIcon icon={faCaretSquareUp} className={`${upvotes?.includes(userId) ? "color-primary-2" : ""}`} />
      </div>
    </div>
  )
}
export default Questions
