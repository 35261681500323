/* global _paq */
import { useEffect ,useContext } from "react"
import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
  faSignOutAlt,
  faUser,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import {
  PLAYER_EVENT_PAUSE,
  PLAYER_EVENT_PLAY
} from "../utils/constants"
import { isLoggedIn, logout } from '../services/auth';
import { logo, logoalt, isMatomoEnabled } from '../data/global';
import { livePlayerContext } from "../context/livePlayer";
import { onDemandPlayerContext } from "../context/onDemandPlayer"
import { SocketContext } from "../context/socket";
import { miniPlayerContext } from "../context/miniPlayer";
import { playerEvent } from "../utils/analytics"
import { handleRemoveLiveViewer } from '../utils/socket/socketOperations';

const Header = ({ siteTitle }) => {
  const [livePlayerState, setlivePlayerState, setInitialLivePlayerState] = useContext(livePlayerContext);
  const [miniPlayerState, setminiPlayerState, setInitialMiniPlayerState] = useContext(miniPlayerContext);
  const [onDemandPlayerState, setOnDemandPlayerState] = useContext(onDemandPlayerContext);
  const { socket, isConnected } = useContext(SocketContext);
  useEffect(() => {
    if(isLoggedIn() && isMatomoEnabled){
      _paq.push(['setUserId', JSON.parse(localStorage.veUser).userId]);
    }
  }, [livePlayerState, miniPlayerState, onDemandPlayerState])
  
  return(
  <header className="header-block background-color-primary-1">
    <div className="container-fluid">
      <div className="header-content d-xs-flex xs-between xs-middle width-full">
        <div className="logo-box">
          <Link to="/">
            <img src={logo} alt={logoalt} />
          </Link>
        </div>
        <div className="event-title xs-margin-right-auto d-xs-none d-sm-block">
          <h1 className="text-size-h3 color-monochrome-1 font-primary-bold mb-0 ml-40">
            {siteTitle}
          </h1>
        </div>
        <div className="text-size-extra-large color-monochrome-1 margin-left-auto">
          <Link
            to={isLoggedIn() ? '/dashboard' : '/'}
            // if there is new class added please make change in length comparator
            className="text-size-extra-large color-monochrome-1 d-xs-flex xs-middle"
          >
            <FontAwesomeIcon icon={faHome} />
            <span className="d-xs-none d-sm-block ml-5">Home</span>
          </Link>
        </div>
        <div className="text-size-extra-large d-xs-flex color-monochrome-1 ml-20">
          <Link
            to="/help/"
            // if there is new class added please make change in length comparator
            className="color-monochrome-1 d-xs-flex xs-middle"
            activeClassName="active"
            onClick={(event) => {
              if (event.currentTarget.classList.length == 4) {
                window.location.reload();
              }
            }}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
            <span className="ml-5 d-xs-none d-sm-block ml-5">Help</span>
          </Link>
        </div>

        {isLoggedIn() && (
          <div className="ml-20 d-xs-flex xs-middle text-size-extra-large color-monochrome-1">
            <FontAwesomeIcon icon={faUser} />
            <span className="d-xs-none d-sm-block font-secondary-regular ml-5">
              {window.localStorage.userName}
            </span>
          </div>
        )}

        {isLoggedIn() ? (
            <div className="ml-20">
              <Link
                to="/"
                className="text-size-extra-large color-monochrome-1 d-xs-flex xs-middle _a_logout"
                onClick={async event => {
                  event.preventDefault()
                  try {
                    if (livePlayerState?.eventType === PLAYER_EVENT_PLAY) {
                      await playerEvent({
                        eventType: PLAYER_EVENT_PAUSE,
                        presentationId: livePlayerState.presentationId,
                        status: livePlayerState.status,
                      })
                      setlivePlayerState({})
                      if(isConnected){
                        handleRemoveLiveViewer(socket,livePlayerState.presentationId)
                      }
                    }

                    if (miniPlayerState?.eventType === PLAYER_EVENT_PLAY) {
                      await playerEvent({
                        eventType: PLAYER_EVENT_PAUSE,
                        presentationId: miniPlayerState.presentationId,
                        status: miniPlayerState.status,
                      })
                      setminiPlayerState({})
                      if(isConnected){
                        handleRemoveLiveViewer(socket,miniPlayerState.presentationId)
                      }
                    }

                    if (onDemandPlayerState?.eventType === PLAYER_EVENT_PLAY) {
                      await playerEvent({
                        eventType: PLAYER_EVENT_PAUSE,
                        presentationId: onDemandPlayerState.presentationId,
                        status: onDemandPlayerState.status,
                      })
                      setOnDemandPlayerState({})
                    }
                    logout(() => {
                      setInitialLivePlayerState()
                      setInitialMiniPlayerState()
                      navigate("/")
                    })
                  } catch (error) {
                    console.error("Error handling player event:", error)
                  }
                }}
                >
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span className="d-xs-none d-sm-block font-secondary-regular ml-5">
                  Logout
                </span>
              </Link>
            </div>
          ) : null}
      </div>
    </div>
  </header>
);}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
