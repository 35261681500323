/* global _paq */
import React, { useEffect, useState, useContext } from "react"
import "../wdyr"
import { navigate, withPrefix } from "gatsby"
import { isLoggedIn, clearClientData } from "../services/auth"
import Heading from "atomic-core/components/patterns/01-atoms/texts/heading/Heading"
import ReactJWPlayer from "react-jw-player"
import PageAside from "../components/streaming/pageAside"
import Slides from "../components/streaming/slides"
import SEO from "../components/seo"
import {
  TOKEN_EXPIRED,
  PRESENTATION_STATUS_LIVE,
  PLAYER_EVENT_PLAY,
  PLAYER_EVENT_PAUSE
} from "../utils/constants"
import { playerEvent } from "../utils/analytics"
import { Translations } from "../utils/translations"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVideo, faImages } from "@fortawesome/free-solid-svg-icons"
import miniplayer from "../images/miniplayer.svg"
import ReactTooltip from 'react-tooltip'
import { miniPlayerContext } from "../context/miniPlayer";
import { livePlayerContext } from "../context/livePlayer";
import { SocketContext } from "../context/socket";
import { isMatomoEnabled } from '../data/global';

import { handleJoinLiveViewer, handleRemoveLiveViewer } from '../utils/socket/socketOperations';
const LivePage = () => {
  const [data, setData] = useState(null)
  const [miniPlayerState, setminiPlayerState] = useContext(miniPlayerContext)
  const [livePlayerState, setlivePlayerState] = useContext(livePlayerContext)
  const { socket, isConnected } = useContext(SocketContext);
  const [eventLiveStatus, setEventLiveStatus] = useState(null)
  const [activeSlideID, setActiveSlideID] = useState(null)
  const [slideshowFullscreen, setSlideshowFullscreen] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)

  const getLiveStream = async () => {
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/live`
    )
    response = await response.json()
    if (response && response.status === 200) {
      setData(response.result)
      setDataLoaded(true)
      if (response.result != null && !miniPlayerState.videoUrl.includes(response.result._id)) {
        setminiPlayerState({ ...miniPlayerState, videoUrl: response.result.videoStreamUrl, videoTitle: response.result.name })
      }
      if (!!response.result && response.result.slides.length) {
        if (response.result.activeSlide) {
          setActiveSlideID(response.result.activeSlide)
        } else {
          setActiveSlideID(response.result.slides[0]["_id"])
        }
      }
    } 
    if (response.status !== 200 && response.error === TOKEN_EXPIRED) {
      clearClientData()
    }
  }
  //This function fetches status from local data.json file which is
  //updated when webcast state is updated from IL
  const getEventLiveStatus = async () => {
    let random = Math.random()
    let response = await fetch(`/data.json?q=${random}`)
    if (response && response.status === 200) {
      response = await response.json()
      if (response.livewebcast != eventLiveStatus) {
        setEventLiveStatus(response.livewebcast)
        if (response.livewebcast) {
          getLiveStream()
        } else {
          setData(null)
          setDataLoaded(true)
          if (livePlayerState && livePlayerState.eventType === PLAYER_EVENT_PLAY || miniPlayerState && miniPlayerState.eventType === PLAYER_EVENT_PLAY) {
            onPause();
          }
        }
      }
    }

    if (response.status !== 200 && response.error === TOKEN_EXPIRED) {
      clearClientData()
    }
  }
  useEffect(() => {
    function handleOnlineStatusChange() {
      if (navigator.onLine) {
        if (
          (livePlayerState && livePlayerState.eventType === PLAYER_EVENT_PAUSE) ||
          (miniPlayerState && miniPlayerState.eventType === PLAYER_EVENT_PAUSE)
        ) {
          playerEvent({
            eventType: PLAYER_EVENT_PAUSE,
            presentationId: data._id,
            status: PRESENTATION_STATUS_LIVE,
          });
          if(isConnected){
            handleRemoveLiveViewer(socket,data._id);
           }
        }
      }
    }
  
    window.addEventListener('online', handleOnlineStatusChange);
  
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
    };
  }, [livePlayerState, miniPlayerState]);
  

useEffect(() => {
  if (eventLiveStatus == null) {
    getEventLiveStatus()
  }
  const checkEventStatusInterval = setInterval(() => {
    getEventLiveStatus()
  }, 5000)

  return () => {
    clearInterval(checkEventStatusInterval);
  };
}, [eventLiveStatus, livePlayerState, miniPlayerState]);

  function onMeta(event) {
    if (
      event &&
      event.metadata &&
      (event.metadata.TIT2 || event.metadata.TIT1)
    ) {
      var slideChangeEvent
      if (event.metadata.TIT1) {
        slideChangeEvent = JSON.parse(event.metadata.TIT1.trim())
      } else if (event.metadata.TIT2) {
        slideChangeEvent = JSON.parse(event.metadata.TIT2.trim())
      }
      if (slideChangeEvent.eventType === "slideChange") {
        setActiveSlideID(slideChangeEvent.value)
      }
    }
  }

  function onPlay() {
    playerEvent({
      eventType: PLAYER_EVENT_PLAY,
      presentationId: data._id,
      status: PRESENTATION_STATUS_LIVE,
    })
   setlivePlayerState(
    {
      eventType: PLAYER_EVENT_PLAY,
      presentationId: data._id,
      status: PRESENTATION_STATUS_LIVE,
    }
   )
   if(isConnected){
    handleJoinLiveViewer(socket,data._id);
   }
  }

  function onPause() {
    playerEvent({
      eventType: PLAYER_EVENT_PAUSE,
      presentationId: data._id,
      status: PRESENTATION_STATUS_LIVE,
    })
    setlivePlayerState({ 
      eventType: PLAYER_EVENT_PAUSE,
      presentationId: data._id,
      status: PRESENTATION_STATUS_LIVE,
     })
     if(isConnected){
      handleRemoveLiveViewer(socket,data._id);
     }
  }
  function onResume() {
    playerEvent({
      eventType: PLAYER_EVENT_PLAY,
      presentationId: data._id,
      status: PRESENTATION_STATUS_LIVE,
    })
    setlivePlayerState({ 
      eventType: PLAYER_EVENT_PLAY,
      presentationId: data._id,
      status: PRESENTATION_STATUS_LIVE,
     })
     if(isConnected){
      handleJoinLiveViewer(socket,data._id);
     }
  }
  
  function onError() {
    playerEvent({
      eventType: PLAYER_EVENT_PAUSE,
      presentationId: data._id,
      status: PRESENTATION_STATUS_LIVE,
    })
    setlivePlayerState({ 
      eventType: PLAYER_EVENT_PAUSE,
      presentationId: data._id,
      status: PRESENTATION_STATUS_LIVE,
     })
     if(isConnected){
      handleRemoveLiveViewer(socket,data._id);
     }
    if (
      document.querySelector(".jw-error-text.jw-reset-text") &&
      document.querySelector(".jw-error-text.jw-reset-text").textContent
    ) {
      document.querySelector(
        ".jw-error-text.jw-reset-text"
      ).innerHTML = `<span style="display:none">${document.querySelector(".jw-error-text.jw-reset-text").textContent
      }</span>
      <div style="cursor: pointer" onclick=window.location.reload()>
      ${Translations.TEXT.CLIICK_TO_RELOAD}
      <img title="Reload" id="resetPlayer" src="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAu0lEQVQ4y83STWoCQRTE8d7pHEYNqPfLDRQ/mDlN8BSKe3OH7k3/spgkBLsD05BFavUo6g/96nUIfyOvjXEa4w3AGJ8MfMWR3PVW04FR2WA+DZlZ24m4TEI+54UH+oZaLSV5wi4/5j3OLXfZ4NYCdIilnTBrAe5YV4EtrqXdY1cFDjiW9koWLQr/RZJLP4RgwMPyKf6OU72NuQuivY1OZ+sg4a1exogM8tPnO/0a/97l7CaKro7Vt/83fQAGYd4MyXoPCAAAAABJRU5ErkJggg=="> 
      <div> `
    }
  }

  function onReady() {
    if(isMatomoEnabled){
      _paq.push(['MediaAnalytics::enableMediaAnalytics']);
      _paq.push(['MediaAnalytics::scanForMedia'])
    }
  }

  if (typeof window !== "undefined" && !isLoggedIn()) {
    navigate("/")
    return null
  }

  return (
    <>
      <SEO title="Live Stream" />
      {data && Object.keys(data).length ? (
        <div className="page live-stream">
          <div className={`page-content`}>
            <Heading
              headingType={"h2"}
              globalModifiers={
                "d-xs-none d-sm-block text-size-h3 font-primary-bold mb-20"
              }
            >
              {data.name}
            </Heading>

            <div className={"container-fluid no-gutters no-gutters"}>
              <div className={"row no-gutters xs-center"}>
                {!miniPlayerState?.showVideoAtBottom && !data.hideSlides && (
                  <div className="col-xs-12  mb-20 d-sm-inline-block d-xs-none">
                    <input
                      id="sizeChanger"
                      type="checkbox"
                      checked={toggle}
                      onChange={() => {
                        setToggle(!toggle)
                      }}
                    />
                    <div className="d-xs-flex xs-center">
                      <div className="mr-10">
                        <FontAwesomeIcon
                          icon={faVideo}
                          className={"svg color-monochrome-2 text-size-regular"}
                        />{" "}
                        Video
                      </div>
                      <div className="sizeChanger">
                        <label htmlFor="sizeChanger" id="sizeChangerLabel">
                          <span className="switch"></span>
                        </label>
                      </div>
                      <div className="ml-10">
                        <FontAwesomeIcon
                          icon={faImages}
                          className={"svg color-monochrome-2 text-size-regular"}
                        />{" "}
                        Presentation
                      </div>
                    </div>
                  </div>
                )}
                {!miniPlayerState?.showVideoAtBottom && (
                  <div
                    className={
                      !toggle
                        ? `col-sm-12 ${data.hideSlides ? "col-md-12" : "col-md-4"} sm-row md-col d-xs-block mb-sm-20`
                        : "col-sm-12 col-md-8"
                    }
                  >
                    <div
                      className="react-jw-player-container pr-sm-0 pr-xs-0"
                      data-name={data.name}
                    >
                      <ReactJWPlayer
                        playerId="live-streaming"
                        playerScript={withPrefix(
                          "js/jwplayer-8.27.1/jwplayer.js"
                        )}
                        file={data.videoStreamUrl}
                        licenseKey={process.env.JWPLAYER_LICENCE}
                        onMeta={onMeta}
                        onPlay={onPlay}
                        onError={onError}
                        onReady={onReady}
                        onPause={onPause}
                        onResume={onResume}
                        customProps={{ title: data.name, pipIcon: "disabled" }}
                      />
                    </div>
                    {!miniPlayerState?.showVideoAtBottom ? (
                      <div className="miniplayer-button-container d-xs-flex xs-center text-size-medium">
                        <a
                          className="pointer-cursor xs-self-center"
                          data-tip={"Switch to MiniPlayer to navigate <br/> to other areas of the platform."}
                          data-multiline={true}
                          data-class="ve-tooltip"

                          onClick={() => {
                            if (livePlayerState && livePlayerState.eventType === PLAYER_EVENT_PLAY) {
                              onPause();
                            }
                             setminiPlayerState({ ...miniPlayerState, showVideoAtBottom: !miniPlayerState?.showVideoAtBottom,presentationId:data._id })
                            }}
                        >
                          <img
                            src={miniplayer}
                            width="20"
                            className="mr-5 mt-5"
                          />
                          <ReactTooltip />
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {!data.hideSlides && data.slides && data.slides.length > 0 && (
                  <div
                    className={
                      miniPlayerState?.showVideoAtBottom
                        ? "col-sm-12 col-md-8 sm-row md-col d-sm-flex d-md-block mb-sm-20"
                        : toggle
                          ? "col-sm-12 col-md-4 sm-row md-col d-sm-flex d-md-block mb-sm-20"
                          : "col-sm-12 col-md-8"
                    }
                  >
                    <Slides
                      slides={data.slides}
                      slideshowFullscreen={slideshowFullscreen}
                      onClick={() =>
                        setSlideshowFullscreen(!slideshowFullscreen)
                      }
                      activeSlideID={miniPlayerState?.showVideoAtBottom && miniPlayerState?.activeSlideFromMP ? miniPlayerState?.activeSlideFromMP : activeSlideID}
                    />
                  </div>
                )}
                {!data.hideSlides && data.slides && data.slides.length == 0 && (
                  <div className={"col-sm-12 col-md-8 slide-text-center"}>
                    No slides currently available
                  </div>
                )}
                <div className={"d-xs-flex col-sm-12 speaker-info xs-wrap"}>
                  <h3
                    className={
                      "d-xs-flex heading width-full mt-20 text-size-medium font-secondary-bold"
                    }
                  >
                    Speaker
                  </h3>
                  <div className={"d-xs-flex width-full xs-wrap"}>
                    {data.speakers &&
                      data.speakers.length &&
                      data.speakers.map((speaker, idx) => (
                        <div
                          key={idx}
                          className="d-xs-flex col-xs-12  col-sm-6 col-md-4 col-lg-3  xs-top mb-20"
                        >
                          <div className="mr-10">
                            <img
                              className="radius-circle"
                              src={speaker.speaker_image_path}
                              height="50px"
                              width="50px"
                            />
                          </div>
                          <div className="font-secondary-regular">
                            <div>{speaker.speaker_name}</div>
                            <div>{speaker.speaker_company}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(data.isPollTabVisible || data.isQuestionTabVisible || data.hasLiveChat) && (
            <PageAside
              presentationId={data._id}
              moderatedQuestions={data.moderatedQuestions}
              isPollTabVisible={data.isPollTabVisible}
              isQuestionTabVisible={data.isQuestionTabVisible}
              isUpvotesEnabled={data.isUpvotesEnabled}
              hasLiveChat={data.hasLiveChat}
            />
          )}
        </div>
      ) : (dataLoaded ? (
        <h3 className="d-xs-flex xs-center xs-middle width-full">
          There is currently no live event. Check agenda for upcoming sessions.
        </h3>
      ) : "")}
    </>
  )
}

export default LivePage
