import React, { useState, useEffect, useRef, useContext } from "react"
import { miniPlayerContext } from "../../context/miniPlayer"
import { livePlayerContext } from "../../context/livePlayer"
import { PLAYER_EVENT_PAUSE } from "../../utils/constants"

const MiniPlayer = () => {
  const location = window.location;
  const [isDragging, setIsDragging] = useState(false)
  const [position, setPosition] = useState({ top: 20, left: 20 })
  const miniPlayerRef = useRef(null)
  const [miniPlayerState, setminiPlayerState] = useContext(miniPlayerContext)
  const [livePlayerState, setlivePlayerState] = useContext(livePlayerContext)
  const [closeBtnState, setCloseBtnState] = useState(true)
  let offsetX = 0;
  let offsetY = 0;

  const miniplayerClose = () => {
    const player = document.getElementById("live-stream-player")
    if (location && location.pathname === "/live") {
      const liveStreamTabObserver = new MutationObserver(() => {
        let livePagePlayerEle = document.getElementById("live-page-player")
        if (livePagePlayerEle) {
          livePagePlayerEle.appendChild(player)
          liveStreamTabObserver.disconnect()
        }
      })
      liveStreamTabObserver.observe(document.body, {
        childList: true,
        subtree: true,
      })
    } else {
      const otherTabObserver = new MutationObserver(() => {
        let layoutPlayerEle = document.getElementById("layout-player")
        if (layoutPlayerEle) {
          layoutPlayerEle.appendChild(player)
          otherTabObserver.disconnect()
        }
      })
      otherTabObserver.observe(document.body, {
        childList: true,
        subtree: true,
      })
      var videoElement = document.querySelector('video.jw-video');
      videoElement.pause();
      setlivePlayerState((prev)=>({...prev, eventType: PLAYER_EVENT_PAUSE }))
    }
    setminiPlayerState((prev)=>({...prev,showVideoAtBottom: false,isAutoMiniPlayer: false}))
  }

  const onMouseDown = e => {
    setIsDragging(true);
    const position = miniPlayerRef.current.getBoundingClientRect()
    offsetX = e.clientX - position.left
    offsetY = e.clientY - position.top
    e.preventDefault();
  }

  const onMouseMove = e => {
    if (!isDragging) return;
    let newLeft = e.clientX - offsetX
    let newTop = e.clientY - offsetY

    const playerWidth = 306;
    const playerHeight = 177;
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    if (newLeft < 0) newLeft = 0
    if (newTop < 0) newTop = 0
    if (newLeft + playerWidth > windowWidth) newLeft = windowWidth - playerWidth
    if (newTop + playerHeight > windowHeight) newTop = windowHeight - playerHeight

    const position = miniPlayerRef.current.getBoundingClientRect()

    if (e.clientX > (window.innerWidth - 108)){
      newLeft = position.left;
    } 
    if (e.clientY > (window.innerHeight - 80)){
      newTop = position.top;
    }
    miniPlayerRef.current.style.left = `${newLeft}px`
    miniPlayerRef.current.style.top = `${newTop}px`
  }

  // To stop dragging when mouse is released
  const onMouseUp = () => {
    setIsDragging(false)
    if(miniPlayerRef.current) setPosition({ top: parseInt(miniPlayerRef.current.style.top), left: parseInt(miniPlayerRef.current.style.left) })
  }

  // Add event listeners for mouse move and mouse up on component mount
  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", onMouseMove)
      document.addEventListener("mouseup", onMouseUp)
    } else {
      document.removeEventListener("mousemove", onMouseMove)
      document.removeEventListener("mouseup", onMouseUp)
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove)
      document.removeEventListener("mouseup", onMouseUp)
    }
  }, [isDragging])

  if (!miniPlayerState.showVideoAtBottom) return null // Don't render the player if it's closed

  return (
    <div
      ref={miniPlayerRef}
      id="mini-player-wrapper"
      className=""
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        cursor: isDragging ? "grabbing" : "grab",
      }}
      onMouseDown={onMouseDown}
      onMouseEnter={() => setCloseBtnState(false)}
      onMouseLeave={() => setCloseBtnState(true)}
    >
      <div className="mini-player-container" id="mini-player">
        {!closeBtnState && (
          <button className="mini-player-close-btn" onClick={miniplayerClose}>
            X
          </button>
        )}
      </div>
    </div>
  )
}

export default MiniPlayer
