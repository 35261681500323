import React, { useState, useEffect, useMemo, useCallback } from "react"

const PlayListChapters = ({
  activeChapterId: activeSlideChapterId,
  chapterList,
}) => {
  const [activeChapterId, setActiveChapterId] = useState(
    activeSlideChapterId || null
  )
  const [isUserSelect, setIsUserSelect] = useState(false)

  useEffect(() => {
    if (activeSlideChapterId !== activeChapterId) {
      if (isUserSelect) {
        setActiveChapterId(activeChapterId)
        setIsUserSelect(false)
      } else {
        setActiveChapterId(activeSlideChapterId)
      }
    }
  }, [activeSlideChapterId, activeChapterId])

  const chapterOptions = useMemo(() => {
    return chapterList.map(chapter => ({
      key: chapter?._id,
      value: chapter?._id,
      name: chapter?.name,
      startTime: chapter?.startTime,
      presentationId: chapter?.presentationId,
      style: {
        backgroundColor:
          activeChapterId === chapter?._id ? "red" : "transparent",
        color: activeChapterId === chapter?._id ? "white" : "black",
      },
    }))
  }, [chapterList, activeChapterId])

  const updateVideoPlayerTime = useCallback(
    (event) => {
      const selectedChapterId = event.target.value;
      const selectedChapter = chapterList.find((chapter) => chapter?._id === selectedChapterId);

      if (!selectedChapter) return;
        const { startTime, presentationId } = selectedChapter;

        const player = window.jwplayer?.(`ondemand-${presentationId}`);
        if (player && typeof player.seek === "function") {
          player.seek(startTime / 1000);
        } else {
          console.error(`Player with ID ondemand-${presentationId} not found.`);
        }

        if (selectedChapterId !== activeChapterId) {
          setActiveChapterId(selectedChapterId);
          setIsUserSelect(true);
        }
    },
    [activeChapterId, chapterList]
  );

  return (
    <select value={activeChapterId || ""} onChange={updateVideoPlayerTime}>
      <option value="" disabled>
        Select a Chapter
      </option>
      {chapterOptions.map(chapter => (
        <option
          key={chapter.key}
          value={chapter.value}
          data-streamtime={chapter.startTime}
          data-presentationid={chapter.presentationId}
          style={chapter.style}
        >
          {chapter.name}
        </option>
      ))}
    </select>
  )
}

export default PlayListChapters
