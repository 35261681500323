import React, { useState, createContext } from "react"

export const livePlayerContext = createContext()

export const LivePlayerProvider = ({ children }) => {
  const initialValues = {
    eventType: "",
    presentationId: "",
    status: "",
    data: null,
    isLoading: true
  };
  const [livePlayerState, setlivePlayerState] = useState(initialValues)
  
  function setInitialLivePlayerState() {
    setlivePlayerState({...initialValues,isLoading:false})
  }

  return (
    <livePlayerContext.Provider value={[livePlayerState, setlivePlayerState, setInitialLivePlayerState]}>
      {children}
    </livePlayerContext.Provider>
  )
}
