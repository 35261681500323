/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
/* global _paq */
import React, { useContext, useEffect, useState } from "react"
import { withPrefix } from "gatsby"
import ReactJWPlayer from "react-jw-player"
import "typeface-work-sans"
import {
    PLAYER_EVENT_PLAY,
    PLAYER_EVENT_PAUSE,
    PRESENTATION_STATUS_LIVE,
} from "../../utils/constants"
import { playerEvent } from "../../utils/analytics"
import { handleJoinLiveViewer, handleRemoveLiveViewer } from '../../utils/socket/socketOperations';
import { isMatomoEnabled } from "../../data/global"
import { Translations } from "../../utils/translations"
import { SocketContext } from "../../context/socket"
import { livePlayerContext } from "../../context/livePlayer"
import { miniPlayerContext } from "../../context/miniPlayer"

const LivePlayer = () => {

    const [livePlayerState, setlivePlayerState] = useContext(livePlayerContext);
    const [miniPlayerState, setminiPlayerState] = useContext(miniPlayerContext);
    const [data, setData] = useState(null);

    const { socket, isConnected } = useContext(SocketContext);

    function onMeta(event) {
        const slideChangeEvent = JSON.parse(
            event?.metadata?.TIT1?.trim() || event?.metadata?.TIT2?.trim() || 'null'
        );

        if (slideChangeEvent?.eventType === "slideChange") {
            setminiPlayerState((prev)=>({...prev,activeSlideFromMP: slideChangeEvent?.value}))
        }
    }

    function onPlay() {
        playerEvent({
            eventType: PLAYER_EVENT_PLAY,
            presentationId: data?._id,
            status: PRESENTATION_STATUS_LIVE,
        })
        setlivePlayerState((prev)=>({...prev, eventType: PLAYER_EVENT_PLAY, presentationId: data?._id, status: PRESENTATION_STATUS_LIVE }))
        if (isConnected) {
            handleJoinLiveViewer(socket, data?._id);
        }
    }

    function onPause() {
        playerEvent({
            eventType: PLAYER_EVENT_PAUSE,
            presentationId: data?._id,
            status: PRESENTATION_STATUS_LIVE,
        })
        setlivePlayerState((prev)=>({...prev, eventType: PLAYER_EVENT_PAUSE, presentationId: data?._id, status: PRESENTATION_STATUS_LIVE}))
        if (isConnected) {
            handleRemoveLiveViewer(socket, data?._id);
        }
    }

    function onResume() {
        playerEvent({
            eventType: PLAYER_EVENT_PLAY,
            presentationId: data?._id,
            status: PRESENTATION_STATUS_LIVE,
        })
        setlivePlayerState((prev)=>({...prev, eventType: PLAYER_EVENT_PLAY, presentationId: data?._id, status: PRESENTATION_STATUS_LIVE }))
        if (isConnected) {
            handleJoinLiveViewer(socket, data?._id);
        }
    }

    function onError() {
        playerEvent({
            eventType: PLAYER_EVENT_PAUSE,
            presentationId: data?._id,
            status: PRESENTATION_STATUS_LIVE,
        })
        setlivePlayerState((prev)=>({...prev, eventType: PLAYER_EVENT_PAUSE, presentationId: data?._id, status: PRESENTATION_STATUS_LIVE }))
        if (isConnected) {
            handleRemoveLiveViewer(socket, data?._id);
        }
        if (
            document.querySelector(".jw-error-text.jw-reset-text") &&
            document.querySelector(".jw-error-text.jw-reset-text").textContent
        ) {
            document.querySelector(
                ".jw-error-text.jw-reset-text"
            ).innerHTML = `<span style="display:none">${document.querySelector(".jw-error-text.jw-reset-text").textContent
            }</span>
          <button id="reloadButtonForPage">
          ${Translations.TEXT.CLIICK_TO_RELOAD}
          <img title="Reload" id="resetPlayer" src="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAu0lEQVQ4y83STWoCQRTE8d7pHEYNqPfLDRQ/mDlN8BSKe3OH7k3/spgkBLsD05BFavUo6g/96nUIfyOvjXEa4w3AGJ8MfMWR3PVW04FR2WA+DZlZ24m4TEI+54UH+oZaLSV5wi4/5j3OLXfZ4NYCdIilnTBrAe5YV4EtrqXdY1cFDjiW9koWLQr/RZJLP4RgwMPyKf6OU72NuQuivY1OZ+sg4a1exogM8tPnO/0a/97l7CaKro7Vt/83fQAGYd4MyXoPCAAAAABJRU5ErkJggg=="> 
          <button> `
        }
        document.getElementById("reloadButtonForPage").addEventListener("click", () => window.location.reload())
    }

    function onReady() {
        if (isMatomoEnabled) {
            _paq.push(['MediaAnalytics::enableMediaAnalytics']);
            _paq.push(['MediaAnalytics::scanForMedia'])
        }
    }

    useEffect(() => {
        if (livePlayerState?.data) {
            setData(livePlayerState?.data)
        }
    }, [livePlayerState]);

    if (!data) {
        return null;
    }

    return (
        <ReactJWPlayer
            playerId="live-stream-player"
            playerScript={withPrefix(
                "js/jwplayer-8.27.1/jwplayer.js"
            )}
            file={data?.videoStreamUrl || ""}
            licenseKey={process.env.JWPLAYER_LICENCE}
            onMeta={onMeta}
            onPlay={onPlay}
            onError={onError}
            onReady={onReady}
            onPause={onPause}
            onResume={onResume}
            customProps={{ title: data?.name, pipIcon: "disabled" }}
        />
    );
};

export default LivePlayer;
