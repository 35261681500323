import '../wdyr';
import { navigate } from 'gatsby';
import React, { useState, useEffect, useContext } from 'react';
import SEO from '../components/seo';
import Resources from '../components/resources';
import DownloadReport from '../components/resources/components/DownloadReport';
import Accordian from '../components/resources/components/Accordian';
import { TOKEN_EXPIRED } from '../utils/constants';
import { clearClientData, isLoggedIn } from '../services/auth';
import { SocketContext } from '../context/socket';
const ResourcesPage = ({location}) => {
  const { socket } = useContext(SocketContext);
  const [resourcesdata, setResourcesData] = useState({
    days: [],
    title: '',
    description: '',
    downloads: [],
  });
  useEffect(() => {
    async function fetchData() {
      let response = await fetch(
        `${process.env.API_URL}/${process.env.EVENT_ID}/resource`,
      );
      response = await response.json();
      if (response && response.status === 200 && response.result) {
        let activeTab = (location.state && location.state.activeTab) ? location.state.activeTab : response.result.activeTabIndex;
        setResourcesData({
          activeTabIndex: activeTab,
          days: response.result.resources.days,
          title: response.result.resources.resource_title,
          description: response.result.resources.resource_description,
          downloads: response.result.resources.generic_docs,
        });
      }

      if (response.status !== 200 && response.error === TOKEN_EXPIRED) {
        clearClientData();
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!socket) return;
    // Subscribe to a channel
    const channel = socket.subscribe(process.env.EVENT_ID);
    channel.watch((data) => {
      if (data?.event === "updateEventResourceAsset" && data?.message?.status === 1) {
        const asset = data?.message?.asset;
        if (asset?.published) {
          setResourcesData((prevData) => {
            return { ...prevData, downloads: [asset, ...prevData.downloads] }
          });
        } else if (!asset?.published) {
          setResourcesData((prevData) => {
            return { ...prevData, downloads: prevData.downloads.filter((resource) => resource._id !== asset?._id) }
          });
        }
      }
      else if (data?.event === "updatePresentationResourceAsset" && data?.message?.status === 1) {
        if (!data?.message?.drupalWebcastId) return;
        setResourcesData(prevResourcesData => {
          const updatedResourcesData = { ...prevResourcesData };
          updatedResourcesData.days.forEach(day => {
            day.presentations.forEach(presentation => {
              if (presentation?.presentation_id === data?.message?.drupalWebcastId) {
                const asset = data?.message?.asset;
                if (asset?.published) {
                  presentation.documents.unshift(asset);
                } else if (!asset?.published) {
                  presentation.documents = presentation?.documents?.filter(resource => resource._id !== asset?._id);
                }
              }
            });
          });
          return updatedResourcesData;
        });
      }
    });
  }, [socket]);

  if (typeof window !== 'undefined' && !isLoggedIn()) {
    navigate('/');
    return null;
  }
  return (
    <>
      <SEO title="Resources" />
      <div className="width-full page resources">
        <h2 className="d-xs-none d-sm-block text-size-h3 font-primary-bold mb-30">
          {resourcesdata.title}
        </h2>
        {!!resourcesdata.description && (
          <div
            dangerouslySetInnerHTML={{ __html: resourcesdata.description }}
          />
        )}
        <div className="commonResouces">
          {resourcesdata.downloads.length > 0 ? (
            <Accordian>
              <div key={2} label="Event level" isOpen>
                <div className="download-section d-sm-flex width-full xs-wrap background-color-monochrome-1 pl-20 pr-20 pb-20 pt-20 pt-xs-10 pl-xs-10 pr-xs-10 pb-xs-10 mb-20">
                  {resourcesdata.downloads.map((data, index) => <DownloadReport data={data} key={index} />)}
                </div>
              </div>
              <div className="d-xs-none" />
            </Accordian>
          ) : (
              ''
            )}
        </div>
        {resourcesdata.days && resourcesdata.days.length ? (
          <Resources
            days={resourcesdata.days}
            activeTabIndex={resourcesdata.activeTabIndex}
            selectedPresentation={location.state && location.state.selectedPresentation? location.state.selectedPresentation: null}
          />
        ) : (
            ''
          )}
      </div>

    </>
  );
};

export default ResourcesPage;
