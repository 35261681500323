import React, { useState, createContext } from 'react'

export const miniPlayerContext = createContext()

export const MiniPlayerProvider = ({ children }) => {

    const initialValues = {
        showVideoAtBottom: false,
        activeSlideFromMP: 0,
        isAutoMiniPlayer: false,
    };

    const [miniPlayerState, setminiPlayerState] = useState(initialValues)

    function setInitialMiniPlayerState() {
        setminiPlayerState(initialValues)
    }

    return (
        <miniPlayerContext.Provider value={[miniPlayerState, setminiPlayerState, setInitialMiniPlayerState]}>
            {children}
        </miniPlayerContext.Provider>
    )
};
